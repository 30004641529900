import { useCallback, useEffect, useRef } from "react";

/**
 * コンポーネントが削除されているか判定する関数を作成します。
 *
 * @example
 * ```ts
 * // 非同期処理がある場合は、処理後に必ずチェック!
 * const isUnmounted = useIsUnmounted();
 * const handleClick = useCallback(() => {
 *   fetch(url)
 *     .then(res => res.ok ? res.text() : "ERROR")
 *     .then(result => {
 *       if (isUnmounted()) {
 *         return;
 *       }
 *       setResult(result);
 *     });
 * }, [url]);
 * ```
 *
 * @returns コンポーネントが削除されているか判定する関数。関数が `true` を返した
 * ら既に削除済み。
 */
export function useIsUnmounted(): () => boolean {
    const unmountedRef = useRef(false);
    const isUnmounted = useCallback(() => unmountedRef.current, []);

    useEffect(
        () => () => {
            unmountedRef.current = true;
        },
        [],
    );

    return isUnmounted;
}
